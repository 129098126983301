<template>
  <div class="c-teachers u-py">
    <aside class="c-teachers__col u-py">
      <ul v-if="lessons.length" class="c-teachers__lesson-list">
        <li
          v-for="lesson in lessons"
          :key="lesson.id"
          class="c-teachers__lesson-list-item"
        >
          <FHButton
            :href="`/fagstoff/${lesson.slug}`"
            defaultStyles
            class="c-teachers__lesson-link"
            :class="{
              'c-teachers__lesson-link--active': lesson.slug === lessonSlug,
            }"
          >
            {{ lesson.name }}
          </FHButton>
        </li>
      </ul>
    </aside>

    <main class="c-teachers__col u-px--3">
      <Breadcrumbs
        class="c-teachers__breadcrumbs u-px u-py"
        v-if="crumbs"
        :crumbs="crumbs"
      />

      <div v-if="currentLesson" class="c-teachers__article-wrapper">
        <VHeading header="h1" class="c-teachers__article-title u-px">
          {{ currentLesson.name }}
        </VHeading>

        <div
          v-if="currentLesson.description"
          class="c-teachers__article-desc u-px"
          v-md-html="currentLesson.description"
        />

        <LessonPlanSection
          v-for="section in currentLesson.scenario"
          :id="createId(section.title)"
          :key="section.id"
          :content="section"
          class="c-teachers__article-section u-px"
        />
      </div>
    </main>
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { fetchUnitBySlug, fetchUnitLessons } from '@/services/strapi'
import { componentNameNormalize } from '@/utils'

import VHeading from '@forlagshuset/v-heading'
import Breadcrumbs from '@forlagshuset/v-breadcrumbs'
import FHButton from '@forlagshuset/v-button'
import LessonPlanSection from '@/components/LessonPlanSection'

export default {
  components: {
    Breadcrumbs,
    VHeading,
    FHButton,

    LessonPlanSection,
  },

  setup(props, ctx) {
    const unitSlug = 'echo-teachers-guide'
    const unit = ref({})
    const lessons = ref([])
    const lessonSlug = computed(
      () => ctx.root.$options.router.history.current.params.lesson,
    )
    const currentLesson = ref({})
    const crumbs = ref([])
    const sectionSlug = computed(
      () => ctx.root.$options.router.history.current.query.section,
    )
    const subSectionSlug = computed(
      () => ctx.root.$options.router.history.current.query.subsection,
    )

    const createId = str => {
      return str.replace(/[^a-zA-Z]/g, '').toLowerCase()
    }

    onMounted(async () => {
      unit.value = await fetchUnitBySlug(unitSlug)
      lessons.value = await fetchUnitLessons(unitSlug)

      currentLesson.value =
        lessons.value.filter(
          l => l.slug === lessonSlug.value || l.id === lessonSlug.value,
        )[0] || lessons.value[0]

      //add unit
      crumbs.value.push({
        type: 'fagstoff',
        name: unit.value.name,
        slug: lessons.value[0].slug,
      })
      //add lesson
      if (currentLesson.value) {
        crumbs.value.push({
          type: 'fagstoff',
          name: currentLesson.value.name,
          slug: currentLesson.value.slug,
        })
      }

      ctx.root.$nextTick(() => {
        if (subSectionSlug.value) {
          document
            .querySelector(`[id="${subSectionSlug.value}"]`)
            .scrollIntoView({
              behavior: 'smooth',
              block: 'start',
              inline: 'nearest',
            })
        } else if (sectionSlug.value) {
          document.querySelector(`[id="${sectionSlug.value}"]`).scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'nearest',
          })
        }
      })
    })

    return {
      unit,
      lessons,
      crumbs,
      componentNameNormalize,
      lessonSlug,
      currentLesson,
      sectionSlug,
      subSectionSlug,
      createId,
    }
  },
}
</script>

<style lang="scss">
.c-teachers {
  display: flex;
  flex-direction: row;
  max-width: $large;
  @include bp-down($medium) {
    flex-direction: column;
  }
}

.c-teachers__col {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 3;
  &:first-child {
    flex: 2;
  }
  &:last-child {
    max-width: calc(1024px - 33%);
  }
}

.c-teachers__article-wrapper {
  max-width: $article-width;
  width: 100%;
}

.c-teachers__breadcrumbs {
  @include bp-down($medium) {
    display: none;
  }

  width: 100%;
  .c-breadcrumbs__home {
    text-decoration: none;
    color: $color-black;
    .c-breadcrumbs__home {
      fill: none;
      width: 0;
    }
    &:before {
      content: 'Home';
      vertical-align: super;
    }
  }
  .c-breadcrumbs__link {
    color: $color-black;
    &:hover {
      text-decoration: none;
    }
  }
}
.c-teachers__lesson-list {
  list-style: none none;
  padding: 0 1rem !important;
  margin: 0;
}
.c-teachers__lesson-list-item {
  padding-left: 0rem;
  &:before {
    display: none;
  }
}
.c-teachers__lesson-link {
  display: block;
  background: none;
  border: none;
  &--active {
    font-weight: 700;
    background-color: $color-brand;
  }
}
.c-teachers__list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 100%;
}

.c-teachers__article-wrapper {
  .c-text-box__body {
    h3,
    h4,
    h5,
    h6 {
      margin-bottom: -0.5rem;
    }

    h3 {
      font-size: 1.4rem;
    }

    h4 {
      font-size: 1.25rem;
    }

    h5,
    h6 {
      font-size: 1.115rem;
    }
  }
}
</style>
