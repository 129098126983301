<template>
  <div class="c-lesson-plan-section"
    :class="{ 'c-lesson-plan-section--opened': isOpened }">
    <VHeading header="h2"
      tabindex="0"
      class="c-lesson__lesson-plan-section-title"
      @click.native="isOpened = !isOpened"
      @keyup.enter.native="isOpened = !isOpened">
      {{ content.title }}
    </VHeading>
    <component v-show="isOpened"
      class="c-lesson-plan-section__wrapper"
      :is="componentNameNormalize(content.__component)"
      :content="content" />
  </div>
</template>

<script>
import { ref, computed, onMounted } from '@vue/composition-api'
import { componentNameNormalize } from '@/utils'
import VHeading from '@forlagshuset/v-heading'
import ComponentLearningElementTextBox from '@/components/LearningElements/TextBox'
import ComponentLearningElementTextElement from '@/components/LearningElements/TextElement'
import ComponentLearningElementArticleElement from '@/components/LearningElements/ArticleElement'
import ComponentLearningElementSectionTagElement from '@/components/LearningElements/SectionTagElement'
import ComponentLearningElementDbokLinkElement from '@/components/LearningElements/DbokLinkElement'
import ComponentLearningElementActivityElement from '@/components/LearningElements/ActivityElement'
import ComponentLearningElementVideoElement from '@/components/ComponentLearningElements/VideoElement'


export default {
  components: {
    VHeading,
    ComponentLearningElementTextBox,
    ComponentLearningElementTextElement,
    ComponentLearningElementArticleElement,
    ComponentLearningElementSectionTagElement,
    ComponentLearningElementDbokLinkElement,
    ComponentLearningElementActivityElement,
    ComponentLearningElementVideoElement
  },
  setup(props, ctx) {
    const isOpened = ref(false)
    const sectionSlug = computed(() => ctx.root.$options.router.history.current.query.section)

    onMounted(() => {
      if (sectionSlug.value === props.content.title.replace(/[^a-zA-Z]/g, "").toLowerCase()) {
        isOpened.value = true
      }
    })

    return {
      isOpened,
      componentNameNormalize
    }
  },

  props: {
    content: Object
  }
}
</script>

<style lang="scss">
.c-lesson-plan-section {
  &--opened {
    .c-lesson__lesson-plan-section-title {
      &:before {
        margin-top: 1.125rem;
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
      }
    }
  }
}
.c-lesson__lesson-plan-section-title {
  display: flex;
  border-bottom: 1px solid $color-medium-grey;
  cursor: pointer;
  &:before {
    content: '';
    border: solid #000;
    border-width: 0 .15rem .15rem 0;
    padding: 0 .25rem;
    transform: rotate(-45deg);
    -webkit-transform: rotate(-45deg);
    position: relative;
    width: 0;
    height: .5rem;
    margin: 1.25rem .75rem 0 .25rem;
  }
}
.c-lesson-plan-section__wrapper {
  .c-text-element__title {
    display: none;
  }
}
</style>